import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { decompressData } from "../common/lib/utils";

const extractData = (response) => decompressData(response.body);

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://2j6zr78sq1.execute-api.eu-central-1.amazonaws.com/prod",
  }),
  endpoints: (builder) => ({
    getInstallations: builder.query({
      query: ({ limit = "" } = {}) => `/installations?limit=${limit}`,
      transformResponse: extractData,
      keepUnusedDataFor: 600,
    }),
    getInstallation: builder.query({
      query: ({ id }) => `/installation?id=${id}`,
      transformResponse: extractData,
    }),
    getCompliance: builder.query({
      query: ({ id }) => `/compliance?id=${id}`,
      transformResponse: extractData,
    }),
    getAccounts: builder.query({
      query: ({ limit = "" } = {}) => `/accounts?limit=${limit}`,
      transformResponse: extractData,
      keepUnusedDataFor: 600,
    }),
    getAccount: builder.query({
      query: ({ id = "", installation = "" }) =>
        `/account?id=${id}&installation=${installation}`,
      transformResponse: extractData,
    }),
    getAccountHolder: builder.query({
      query: ({ id = "", account = "" }) =>
        `/accountholder?id=${id}&account=${account}`,
      transformResponse: extractData,
    }),
    getCountries: builder.query({
      query: () => `/countries`,
      transformResponse: extractData,
    }),
    getHolding: builder.query({
      query: () => `/holdings`,
      transformResponse: extractData,
    }),
    getTransaction: builder.query({
      query: ({ account = "", holder = "" }) =>
        `/transaction?account=${account}&holder=${holder}`,
      transformResponse: extractData,
    }),
    getEsdTransaction: builder.query({
      query: () => `/esdtransaction`,
      transformResponse: extractData,
    }),
  }),
});

export const {
  useGetInstallationsQuery,
  useGetInstallationQuery,
  useGetAccountsQuery,
  useGetAccountQuery,
  useGetAccountHolderQuery,
  useGetComplianceQuery,
  useGetCountriesQuery,
  useGetHoldingQuery,
  useGetTransactionQuery,
  useGetEsdTransactionQuery,
} = apiSlice;
export default apiSlice;
