import { configureStore } from "@reduxjs/toolkit";
import installationsReducer from "../features/Installations/installationsSlice";
import accountsReducer from "../features/Accounts/accountsSlice";
import countriesReducers from "../features/Countries/countriesSlice";
import { apiSlice } from "../features/api/apiSlice";

const store = configureStore({
  reducer: {
    installations: installationsReducer,
    accounts: accountsReducer,
    countries: countriesReducers,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
