import { Table } from "react-bootstrap";
import { formatNumber } from "../../common/lib/utils";

const ComplianceTable = ({ data }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th className="text-center">Year</th>
          <th className="text-center">Allocated Allowances</th>
          <th className="text-center">Verified Emissions</th>
          <th className="text-center">Surrendered Allowances</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            <td>{item.year}</td>
            <td className="text-center">{formatNumber(item.allocatedTotal)}</td>
            <td className="text-center">{formatNumber(item.verified)}</td>
            <td className="text-center">{formatNumber(item.surrendered)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ComplianceTable;
