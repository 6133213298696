import { Table } from "react-bootstrap";
import { formatDate } from "../common/lib/utils";
import { Link } from "react-router-dom";
const AccountInformation = ({ account }) => {
  return (
    <div className="box-text">
      <Table striped borderless>
        <thead>
          <tr>
            <th colSpan="2" className="text-center">
              Account Information
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Account Type</td>
            <td>{account.accountType}</td>
          </tr>
          <tr>
            <td>Opening date</td>
            <td>{formatDate(account.openingDate)}</td>
          </tr>
          <tr>
            <td>Closing date</td>
            <td>{formatDate(account.closingDate)}</td>
          </tr>
          {account.installation_id ? (
            <tr>
              <td>Related Installation</td>
              <td>
                <Link
                  to={`https://euets.info/installation/${account.installation_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {account.installationName}
                </Link>
              </td>
            </tr>
          ) : (
            ""
          )}
          <tr>
            <td>Registered in</td>
            <td>{account.tradingSystem}</td>
          </tr>
          {account.tradingSystem_id === "euets" ? (
            <tr>
              <td colSpan="2" className="text-center">
                <Link
                  to={`https://ec.europa.eu/clima/ets/singleAccount.do?accountID=${account.id}&action=details&languageCode=en&registryCode=${account.registry_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Official EUTL page
                </Link>
              </td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AccountInformation;
