// options for the apiloader
const LOADER_OPTIONS = {
  apiKey: process.env.REACT_APP_GMAPKEY,
  version: "weekly",
  libraries: [],
};

// options for the map itself
const MAP_OPTIONS = {
  center: {
    lat: 50,
    lng: 10,
  },
  zoom: 5,
  minZoom: 2,
  streetViewControl: false,
  fullscreenControl: false,
};

// options for the marker clusterer superclusterer algorithm
// @see https://github.com/mapbox/supercluster#readme
const CLUSTERER_OPTIONS = {
  radius: 50,
  maxZoom: 9,
};

export { CLUSTERER_OPTIONS, MAP_OPTIONS, LOADER_OPTIONS };
