import Plot from "react-plotly.js";
import { COLOR_CATEGORIES } from "../../common/constants";

const SunburstPlotly = ({ data, year, dataType }) => {
  const esdData = data.filter(
    (i) => i.year === year && i.Activity === "EU Effort Sharing"
  );
  const euetsData = data.filter(
    (i) => i.year === year && i.Activity !== "EU Effort Sharing"
  );
  const euetsLabel = "EU Emissions Trading System";
  const totalLabel = "";
  const esdLabel = "EU Effort Sharing";

  // extract euets activity values
  const plotData = euetsData.reduce(
    (agg, i) => {
      agg.labels.push(i.Activity);
      agg.parents.push(euetsLabel);
      agg.values.push(i[dataType]);
      agg.marker.colors.push(COLOR_CATEGORIES[i.Activity]);
      agg.leaf.opacity.push(0.2);
      return agg;
    },
    {
      type: "sunburst",
      labels: [totalLabel],
      parents: [""],
      values: [0],
      hovertext: [""],
      outsidetextfont: { size: 20, color: "#377eb8" },
      leaf: { opacity: [1] },
      marker: { line: { width: 2 }, colors: [""] },
      branchvalues: "total",
      //hoverinfo: "percent root",
      hovertemplate: "%{label}: %{value:.2f} Mt<extra></extra>",
      sort: false,
    }
  );
  // aggregated euets value
  plotData.labels.push(euetsLabel);
  plotData.parents.push(totalLabel);
  plotData.values.push(plotData.values.reduce((agg, i) => agg + i, 0));
  plotData.marker.colors.push("#000000");
  plotData.leaf.opacity.push(0.8);

  // esd value
  plotData.labels.push(esdLabel);
  plotData.parents.push(totalLabel);
  plotData.marker.colors.push("#0b04d6");
  plotData.leaf.opacity.push(0.8);
  if (esdData.length > 0) {
    plotData.values.push(esdData[0][dataType]);
  } else {
    plotData.values.push(0);
  }
  // update the total value
  plotData.values[0] = plotData.values.slice(-2).reduce((agg, i) => agg + i, 0);
  // unit conversion
  plotData.values = plotData.values.map((i) => i / 1000000);

  // graph layout
  var layout = {
    autosize: true,
    margin: { l: 5, r: 5, b: 5, t: 5 },
  };

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      <Plot
        data={[plotData]}
        layout={layout}
        // config={{
        //   responsive: true,
        // }}
      />
    </div>
  );
};

export default SunburstPlotly;
