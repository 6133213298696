import { createBrowserRouter } from "react-router-dom";
import Layout from "../features/common/layout/Layout";
import Accounts from "../features/Accounts/Accounts";
import Background from "../features/Background/Background";
import Installations from "../features/Installations/Installations";
import LandingPage from "../features/LandingPage/LandingPage";
import Installation from "../features/Installation/Installation";
import Account from "../features/Account/Account";
import AccountHolder from "../features/AccountHolder/AccountHolder";
import Countries from "../features/Countries/Countries";
import Download from "../features/Download/Download";
import Contact from "../features/Contact/Contact";
import ErrorPage from "../features/ErrorPage/ErrorPage";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/installations",
        element: <Installations />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/installation/:id",
        element: <Installation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/accounts",
        element: <Accounts />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/account/:id",
        element: <Account />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/accountholder/:id",
        element: <AccountHolder />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/background",
        element: <Background />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/download",
        element: <Download />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/countries",
        element: <Countries />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/contact",
        element: <Contact />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

export default router;
