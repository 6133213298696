import { Accordion } from "react-bootstrap";
import ComplianceGraph from "./Compliance/ComplianceGraph";
import ComplianceTable from "./Compliance/ComplianceTable";
import MarkdownFile from "../common/ui/MarkdownFile";
import AccordionItem from "../common/ui/AccordionItem";

const ComplianceInformation = ({ data, account_id, registry }) => {
  // preprocess data by sorting by year
  const processed_data = [...data].sort((a, b) => a.year - b.year);
  return (
    <Accordion defaultActiveKey="1" alwaysOpen>
      <AccordionItem eventKey="0" header="Background">
        <MarkdownFile filePath="/markdown/installationPage/compliance_background.md" />
      </AccordionItem>
      <AccordionItem eventKey="1" header="Compliance Graph">
        <ComplianceGraph data={processed_data} account_id={account_id} registry={registry}/>
      </AccordionItem>
      <AccordionItem eventKey="2" header="Compliance Table">
        <ComplianceTable data={processed_data} />
      </AccordionItem>
    </Accordion>
  );
};

export default ComplianceInformation;
