import { Accordion } from "react-bootstrap";
import AccordionItem from "../../common/ui/AccordionItem";
import EuetsComplianceGraph from "./EuetsComplianceGraph";
import EsdComplianceGraph from "./EsdComplianceGraph";
import Transactions from "../../Transactions/Transactions";
const Compliance = () => {
  return (
    <>
      <Accordion defaultActiveKey={"0"} alwaysOpen>
        <AccordionItem
          header="Compliance in the EU Emissions Trading System"
          eventKey="0"
        >
          <EuetsComplianceGraph />
        </AccordionItem>
      </Accordion>
      <Accordion defaultActiveKey={"0"} alwaysOpen>
        <AccordionItem
          header="Compliance under the Effort Sharing Decision"
          eventKey="0"
        >
          <EsdComplianceGraph />
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem header="Effort Sharing Transactions" eventKey="0">
          <Transactions esd={true} />
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default Compliance;
