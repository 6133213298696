import { Accordion } from "react-bootstrap";
import AccordionItem from "../common/ui/AccordionItem";
import MarkdownFile from "../common/ui/MarkdownFile";
import { Link } from "react-router-dom";
const Contact = () => {
  return (
    <Accordion defaultActiveKey={"0"}>
      <AccordionItem eventKey="0" header="Contact">
        <MarkdownFile filePath="/markdown/contactPage/contact.md" />
      </AccordionItem>
      <AccordionItem eventKey="1" header="About Me">
        {/* <MarkdownFile filePath="/markdown/contactPage/aboutMe.md" /> */}
        Please visit my{" "}
        <Link to="https://abrell.eu" target="_blank">
          personal homepage
        </Link>
      </AccordionItem>
      {/* <AccordionItem eventKey="2" header="My Research">
        <MarkdownFile filePath="/markdown/contactPage/research.md" />
      </AccordionItem> */}
    </Accordion>
  );
};

export default Contact;
