import { createSlice } from "@reduxjs/toolkit";
import apiSlice from "../api/apiSlice";

const initialState = {
  accountFilter: {
    registry: [],
    accountType: [],
    tradingSystem: [],
    accountText: "",
  },
  accountData: [],
  filteredAccountData: [],
};

const filterAccounts = (data, accountFilter) => {
  return data.filter((item) => {
    let isRegistry = true;
    let isAccountType = true;
    let isAccount = true;
    let isTradingSystem = true;

    if (accountFilter.registry.length !== 0) {
      isRegistry = accountFilter.registry.includes(item.registry);
    }
    if (accountFilter.accountType.length !== 0) {
      isAccountType = accountFilter.accountType.includes(item.accountType);
    }
    if (accountFilter.accountText.length !== 0) {
      const toSearch = accountFilter.accountText;
      isAccount =
        (item.name && item.name.toLowerCase().includes(toSearch)) ||
        (item.holderName && item.holderName.toLowerCase().includes(toSearch));
    }
    if (accountFilter.tradingSystem.length !== 0) {
      isTradingSystem = accountFilter.tradingSystem.includes(
        item.tradingSystem
      );
    }
    return isRegistry && isAccountType && isAccount && isTradingSystem;
  });
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState: initialState,
  reducers: {
    updateFilter: (state, action) => {
      // assume the action to provide the field of the filter to be updated
      // together with the new values for that field, i.e.
      // payload = {field: FilterValue}
      state.accountFilter = {
        ...state.accountFilter,
        ...action.payload,
      };
      state.filteredAccountData = filterAccounts(
        state.accountData,
        state.accountFilter
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAccounts.matchFulfilled,
      (state, action) => {
        state.accountData = action.payload;
        state.filteredAccountData = filterAccounts(
          state.accountData,
          state.accountFilter
        );
      }
    );
  },
});

export const accountsActions = accountsSlice.actions;
export default accountsSlice.reducer;
