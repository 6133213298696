import { COLOR_CATEGORIES } from "../../common/constants";
import "./Legend.css";
import { Row, Col } from "react-bootstrap";
import { installationsActions } from "../installationsSlice";
import { useDispatch, useSelector } from "react-redux";

const Legend = () => {
  const { installationFilter } = useSelector((state) => state.installations);
  const activityFilter = installationFilter.activityCategory;
  const dispatch = useDispatch();

  const selectCategory = (category) => {
    let newFilter;
    if (activityFilter.includes(category)) {
      newFilter = activityFilter.filter((item) => item !== category);
    } else {
      newFilter = [...activityFilter, category];
    }
    dispatch(
      installationsActions.updateFilter({ activityCategory: newFilter })
    );
  };
  return (
    <Row className="justify-content-md-center">
      {Object.keys(COLOR_CATEGORIES)
        .filter((k) => k !== "EU Effort Sharing")
        .map((cat, i) => {
          return (
            <Col
              key={i}
              xs="auto"//{6}
              sm="auto"//{4}
              className={`px-1  ${
                activityFilter.length === 0 || activityFilter.includes(cat)
                  ? "legend_selected"
                  : "legend"
              }`}
              id={`map-legend-${cat.replace(/ /g, "-")}`}
            >
              <span
                className="mx-1 dot with_pointer"
                style={
                  activityFilter.length === 0 || activityFilter.includes(cat)
                    ? { backgroundColor: `${COLOR_CATEGORIES[cat]}` }
                    : {}
                }
                onClick={() => selectCategory(cat)}
              ></span>
              <span
                className="with_pointer ml-1"
                onClick={() => selectCategory(cat)}
              >{`${cat}`}</span>
            </Col>
          );
        })}
    </Row>
  );
};

export default Legend;
