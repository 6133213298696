import pako from "pako";

/**
 * Produces range of integer numbers
 * @param {Int} start
 * @param {Int} stop
 * @param {Int} step
 * @returns
 */
const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

/**
 *
 * @param {Array} data objects to be aggregated
 * @param {Array} grouper columns to aggregate over
 * @param {Array} values values to aggregate
 * @returns {Array} of objects with aggregated values by group
 *
 * Example
 * const arr = [
 *    { shape: "square", color: "red", used: 1, instances: 1 },
 *    { shape: "square", color: "red", used: 2, instances: 1 },
 *    { shape: "square", color: "red", used: 2, instances: 1 },
 *    { shape: "square", color: "blue", used: 4, instances: 5 },
 *    { shape: "square", color: "blue", used: 4, instances: 4 },
 *    { shape: "circle", color: "blue", used: 0, instances: 0 },
 *    { shape: "circle", color: "red", used: 1, instances: 1 },
 *    { shape: "circle", color: "red", used: 1, instances: 0 },
 * ];
 * groupData(arr, ["shape", "color"], ["used"])
 * return
 * [
 *  {color: "red", shape: "square", used: 5},
 *  {color: "blue", shape: "square", used: 8},
 *  {color: "blue", shape: "circle", used: 0},
 *  {color: "red", shape: "circle", used: 2}
 * ]
 */
const groupData = (data, grouper, values) => {
  const groupedData = data.reduce((groups, item) => {
    // create group key
    const groupKey = grouper.reduce((agg, i) => agg.concat(item[i]), "");
    // try to get group or create new one
    const entry =
      groups.get(groupKey) ||
      Object.assign(
        {},
        grouper.reduce((agg, i) => {
          agg[i] = item[i];
          return agg;
        }, {}),
        values.reduce((agg, i) => {
          agg[i] = 0;
          return agg;
        }, {})
      );
    // assign values
    values.map((v) => (entry[v] = entry[v] + item[v]));
    //console.log(entry, groups)
    return groups.set(groupKey, entry);
  }, new Map());
  return [...groupedData.values()];
};

/** getMarkdownFile Fetch markdown file as string from public folder
 * @param {String} path to markdown file including filename
 * @param {Function} setState to set the state variable
 * @param {String} key state is assumed to be an object and the value of the
 *    file is added using this key
 * 
 * Typical usage example: 
 *  const [content, setContent] = useState({});
    useEffect(() => {
    getMarkdownFile({
      path: "/markdown/landingPage/welcome.md",
      setState: setContent,
      key: "welcome",
    });
  }, []);
 */
const getMarkdownFile = ({ path, setState, key }) => {
  fetch(path)
    .then((res) => res.text())
    .then((res) => setState({ [key]: res }))
    .catch((error) => console.error(`Failed to fetch markdown file: ${error}`));
};

/**
 * Formating of dates
 * @param {String} inputDate with JSON formated date as string
 * @returns
 */
const formatDate = (inputDate) => {
  //return inputDate ? new Date(inputDate).toLocaleDateString() : ""; //.toGMTString().substring(0, 16)
  return inputDate ? new Date(inputDate).toISOString().split('T')[0] : ""; //.toGMTString().substring(0, 16)
};

/**
 * Formats integer adding thousand separator
 * @param {Numeric} num number ot be formatted
 * @param {Sting} thousands thousands separator
 * @returns
 */
const formatNumber = (num, thousands = " ") => {
  if (num) {
    let z = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
    return z;
  }
  return num;
};

/**
 *
 * @param {string} input compressed input string
 * @returns Json object
 */

const decompressData = (input) => {
  const compressedData = Uint8Array.from(window.atob(input), (c) =>
    c.charCodeAt(0)
  );
  const decompressedData = pako.inflate(compressedData, { to: "string" });
  const jsonObject = JSON.parse(decompressedData);
  return jsonObject;
};

/**
 * Given array of objects extract object with unique id
 * and associated value
 *
 * @param {Array} data Array of objects
 * @param {String} idColumn Name of column used as identifier
 * @param {String} valuesColumn Name of column used as value
 */
const getUniqueValues = (data, idColumn, valueColumn) => {
  const indexNotUnique = () => (this.message = "ID-Value pairs are not unique");
  const res = {};
  data.forEach((i) => {
    if ((i[idColumn] in res) & (i[valueColumn] !== res[i[idColumn]])) {
      throw new indexNotUnique();
    } else {
      res[i[idColumn]] = i[valueColumn];
    }
  });

  // sort the array
  const ordered = Object.keys(res)
    .sort()
    .reduce((obj, key) => {
      obj[key] = res[key];
      return obj;
    }, {});

  return ordered;
};

export {
  getUniqueValues,
  decompressData,
  formatNumber,
  formatDate,
  getMarkdownFile,
  groupData,
  arrayRange,
};
