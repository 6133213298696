import { Container, Row, Col, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetAccountHolderQuery } from "../api/apiSlice";
import AccountInformation from "./AccountInformation";
import HolderInformation from "./HolderInformation";
import AccountsTable from "../Accounts/AccountsTable";
import AccordionItem from "../common/ui/AccordionItem";
import LoadingOverlay from "../common/ui/LoadingOverlay";
import Transactions from "../Transactions/Transactions";

const Account = () => {
  const { id } = useParams();
  const { data: accountData } = useGetAccountHolderQuery({
    account: id,
  });

  if (accountData) {
    const related_accounts = accountData;
    const account = related_accounts.filter((item) => item.id === +id)[0];
    return (
      <Container>
        <h1 className="headline">
          Account <br />
          {account.name}
        </h1>
        <Row>
          <Col lg={6} sd={12}>
            <AccountInformation account={account} />
          </Col>
          <Col lg={6} sd={12}>
            <HolderInformation account={account} />
          </Col>
        </Row>
        <Accordion defaultActiveKey="0" alwaysOpen>
          <AccordionItem eventKey="0" header="Related Accounts">
            <AccountsTable data={related_accounts} />
          </AccordionItem>
          <AccordionItem eventKey="1" header="Transactions">
            <Transactions account={id} />
          </AccordionItem>
        </Accordion>
      </Container>
    );
  }
  return <LoadingOverlay headline="Account" />;
};

export default Account;
