import Select from "react-select";

/**
 * Component for drop down fields
 * @param {Object} props properties of component
 *              has to contain
 *              - data object that contains values as keys and labels as entries
 *              - defaultValue: array of already chosen elements
 * @returns DropdownSelect component
 */
const DropdownSelect = (props, ref) => {
  // extract options in correct format
  const options = Object.keys(props.data).map((key) => {
    return { value: key, label: props.data[key] };
  });
  // get default options
  const defaultOptions = Object.keys(props.data)
    .filter((key) => props.defaultValue.includes(key))
    .map((key) => {
      return { value: key, label: props.data[key] };
    });

  return (
    <Select
      options={options}
      value={defaultOptions}
      isMulti
      closeMenuOnSelect={true}
      placeholder={props.placeholder}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        container: (provided) => ({
          ...provided,
          minWidth: "20rem",
        }),
      }}
      onChange={(value) => props.onChange(value)}
    />
  );
};

export default DropdownSelect;
