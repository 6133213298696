import { Fragment } from "react";

import "./Overlay.css";
import { PacmanLoader } from "react-spinners";

const Overlay = ({ children }) => {
  return (
    <Fragment>
      <div className="overlay">
        <div className="overlay__background" />
        <div className="overlay__container">
          <PacmanLoader color="#faf5f5" size={45} />
          <h1>Load Data</h1>
        </div>
        {children}
      </div>
    </Fragment>
  );
};

export default Overlay;
