import { Dropdown, DropdownButton } from "react-bootstrap";

/**
 * @param {Object} options with values as key and label as entry
 * @param {function} onChange
 * @param {any} defaultValue
 * @returns
 */
const DropdownButtonSelector = ({ options, onSelect, defaultValue, title }) => {
  return (
    <DropdownButton variant="secondary" title={title} onSelect={onSelect}>
      {Object.keys(options).map((y) => (
        <Dropdown.Item eventKey={y} value={options[y]} key={y}>
          {options[y]}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DropdownButtonSelector;
