const LAST_YEAR_TO_PLOT = 2023;

const COLOR_CATEGORIES = {
  Combustion: "#0c0d0d",
  Refineries: "#475fa8",
  "Coke ovens": "#8f622f",
  Metal: "#464cfa",
  "Cement and Lime": "#15d458",
  "Glass and Ceramics": "#275e09",
  "Pulp and Paper": "#8d3ea3",
  Aircrafts: "#ede61f",
  Chemicals: "#87c4ab",
  "Carbon Capture": "#99372c",
  "Hydrogen Production": "#ca0ef0",
  Other: "#f54c4c",
  "EU Effort Sharing": "#FD0117",
  "Maritime": "#000099",
};

const COLOR_COMPLIANCE = {
  surrendered: "#0E4C03",
  allocated: "#1330E3",
  verified: "#000000",
};

export { LAST_YEAR_TO_PLOT, COLOR_CATEGORIES, COLOR_COMPLIANCE };
