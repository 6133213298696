import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
const HolderInformation = ({ account }) => {
  return (
    <div className="box-text">
      <Table striped borderless>
        <thead>
          <tr>
            <th colSpan="2" className="text-center">
              Account Holder
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colSpan="2"
              className="text-center"
              style={{ whiteSpace: "pre-line" }}
            >
              {account.holderAddress ? (
                <Link
                  to={`/accountholder/${account.accountHolder_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {account.holderAddress}
                </Link>
              ) : (
                ""
              )}
            </td>
          </tr>
          <tr>
            <td>Company Registration Number</td>
            <td>
              {account.companyRegistrationNumber
                ? account.companyRegistrationNumber
                : ""}
            </td>
          </tr>
          <tr>
            <td>Bureau van Dijk ORBIS Identifier</td>
            <td>{account.bvdId ? account.bvdId : ""}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default HolderInformation;
