import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { formatNumber } from "../../common/lib/utils";
import { LAST_YEAR_TO_PLOT } from "../../common/constants";
import { COLOR_COMPLIANCE } from "../../common/constants";

const EsdComplianceGraph = () => {
  const { countriesPlotData, countriesFilter } = useSelector(
    (state) => state.countries
  );

  // extract data for plotting
  const years = [
    ...new Set(
      countriesPlotData
        .filter((i) => i.Activity === "EU Effort Sharing")
        .reduce((agg, item) => [...agg, item.year], [])
    ),
  ]
    .sort((a, b) => a - b)
    .filter((i) => i <= LAST_YEAR_TO_PLOT);
  const series = countriesPlotData
    .filter(
      (i) => i.year <= LAST_YEAR_TO_PLOT && i.Activity === "EU Effort Sharing"
    )
    .sort((a, b) => a.year - b.year)
    .reduce(
      (agg, item) => {
        agg[0].data.push(item.allocated);
        agg[1].data.push(item.verified);
        agg[2].data.push(item.surrendered);
        return agg;
      },
      [
        {
          name: "Allocated Allowances",
          data: [],
          color: COLOR_COMPLIANCE["allocated"],
        },
        {
          name: "Verified Emissions",
          data: [],
          color: COLOR_COMPLIANCE["verified"],
        },
        {
          name: "Surrendered Allowances",
          data: [],
          color: COLOR_COMPLIANCE["surrendered"],
        },
      ]
    );

  const options = {
    chart: {
      id: "esd_compliance",
    },
    xaxis: {
      categories: years,
      labels: {
        formatter: (x) => x.toString().slice(-2),
      },
      title: {
        text: "Year [20xx]",
        style: {
          cssClass: "graphs__xaxis",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (x) => formatNumber(x, " "),
      },
      title: {
        text: "Emissions and Allowances in the ESD [tCO2]",
        style: {
          cssClass: "graphs__yaxis",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      offsetY: 10,
    },
  };

  // if no data selected return a message that a country has to be selected
  // else return the graph
  if (countriesFilter.countries.length !== 0) {
    return (
      <div className="compliance-country-chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="400px"
        />
      </div>
    );
  }

  return <div></div>;
};

export default EsdComplianceGraph;
