import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const AccountInformation = ({ accounts }) => {
  return (
    <div className="box-text">
      <Table striped borderless>
        <thead>
          <tr>
            <th colSpan="3" className="text-center">
              Operator Holding Accounts
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="3">
              Operator Holding Accounts are the accounts used by the
              installation to exchange allowances with other participants in the
              Trading System.
            </td>
          </tr>
          <tr>
            <th>Account</th>
            <th>Account Holder</th>
            <th>Account Type</th>
          </tr>
          {accounts.map((account, i) => (
            <tr key={i}>
              <td>
                <Link to={`/account/${account.id}`}>{account.name}</Link>
              </td>
              <td>
                <Link to={`/accountholder/${account.accountHolder_id}`}>
                  {account.holderName}
                </Link>
              </td>
              <td>{account.accountType}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AccountInformation;
