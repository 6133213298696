import { Container } from "react-bootstrap";
import Chart from "react-apexcharts";
import { COLOR_COMPLIANCE, LAST_YEAR_TO_PLOT } from "../../common/constants";
import { formatNumber } from "../../common/lib/utils";
import { Link } from "react-router-dom";

const ComplianceGraph = ({ data, account_id, registry }) => {
  const { years, verified, allocated, surrendered } = data.reduce(
    (acc, item) => {
      if (item.year <= LAST_YEAR_TO_PLOT) {
        acc.years.push(item.year);
        acc.verified.push(item.verified);
        acc.allocated.push(item.allocatedTotal);
        acc.surrendered.push(item.surrendered);
      }
      return acc;
    },
    { years: [], verified: [], allocated: [], surrendered: [] }
  );
  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: years,
      labels: {
        formatter: (x) => x.toString().slice(-2),
      },
      title: {
        text: "Year [20xx]",
        style: {
          cssClass: "graphs__xaxis",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (x) => formatNumber(x, " "),
      },
      title: {
        text: "Emissions and Allowances [tCO2]",
        style: {
          cssClass: "graphs__yaxis",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      COLOR_COMPLIANCE["allocated"],
      COLOR_COMPLIANCE["verified"],
      COLOR_COMPLIANCE["surrendered"],
    ],
    legend: {
      offsetY: 10,
    },
  };

  const series = [
    {
      name: "Allocated Allowances",
      data: allocated,
    },
    {
      name: "Verified Emissions",
      data: verified,
    },
    {
      name: "Surrendered Allowances",
      data: surrendered,
    },
  ];
  return (
    <Container>
      <div className="mixed-chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="400px"
        />
      </div>
      <div>
        Source: <Link to={`https://ec.europa.eu/clima/ets/ohaDetails.do?accountID=${account_id}&action=all&languageCode=en&registryCode=${registry}`} target="_blank">Official EUTL Page</Link> 
      </div>
    </Container>
  );
};

export default ComplianceGraph;
