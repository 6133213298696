import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import classes from "./Layout.module.css";
import MainNavigation from "./MainNavigation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_ANALYTICSKEY, {
  debug: false,
});

const Layout = (props) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <Fragment>
      <MainNavigation />
      <div className={classes.main}>
        <Outlet />
      </div>
    </Fragment>
  );
};

export default Layout;
