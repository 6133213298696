import { Table } from "react-bootstrap";

const InstallationInformation = ({ installation }) => {
  return (
    <div className="box-text">
      <Table striped borderless>
        <thead>
          <tr>
            <th colSpan="2" className="text-center">
              Installation Details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Address</td>
            <td style={{ whiteSpace: "pre-line" }}>{installation.address}</td>
          </tr>
          {installation.isMaritimeOperator && (
            <tr>
              <td>Shipping Company</td>
              <td style={{ whiteSpace: "pre-line" }}>
                {installation.shippingCompany}
              </td>
            </tr>
          )}
          {installation.isMaritimeOperator && (
            <tr>
              <td>IMO ID</td>
              <td style={{ whiteSpace: "pre-line" }}>{installation.imoID}</td>
            </tr>
          )}
          {installation.isAircraftOperator && (
            <tr>
              <td>Identifier under EC 748/2009</td>
              <td style={{ whiteSpace: "pre-line" }}>
                {installation.ec748_2009Code}
              </td>
            </tr>
          )}
          <tr>
            <td>Regulated in</td>
            <td style={{ whiteSpace: "pre-line" }}>
              {installation.tradingSystem}
            </td>
          </tr>
          <tr>
            <td>Registry</td>
            <td>{installation.registry}</td>
          </tr>
          <tr>
            <td>Activity</td>
            <td>{installation.activity_id + ": " + installation.activity}</td>
          </tr>
          <tr>
            <td>Nace</td>
            <td>
              {installation.nace_id
                ? installation.nace_id + ": " + installation.nace
                : ""}
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="text-center">
              {installation.validAccountId && (
                <a
                  href={`https://ec.europa.eu/clima/ets/ohaDetails.do?accountID=${installation.validAccountId}&action=all&languageCode=en&registryCode=${installation.registry_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Official EUTL page
                </a>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default InstallationInformation;
