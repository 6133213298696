import { useSelector } from "react-redux";
import { LAST_YEAR_TO_PLOT } from "../../common/constants";
import { Row, ButtonGroup } from "react-bootstrap";
import { arrayRange } from "../../common/lib/utils";
import { useState } from "react";
import DropdownButtonSelector from "../../common/ui/DropdownButtonSelector";
import SunburstPlotly from "./SunburstPlotly";

const EmissionSourcesGraph = () => {
  const { countriesPlotData, countriesFilter } = useSelector(
    (state) => state.countries
  );
  // data selection options
  const [year, setYear] = useState(LAST_YEAR_TO_PLOT);
  const [dataType, setDataType] = useState("verified");

  // selectors for data selection
  // select field for year
  const yearOptions = arrayRange(2005, LAST_YEAR_TO_PLOT, 1).reduce(
    (agg, year) => {
      agg[year] = `${year}`;
      return agg;
    },
    {}
  );

  // select field for year
  const dataTypeOptions = {
    verified: "Verified Emissions",
    allocated: "Allowances allocated for free",
  };

  if (countriesFilter.countries.length !== 0) {
    return (
      <>
        <Row className="p-1">
          <ButtonGroup className="w-100">
            <DropdownButtonSelector
              title={year}
              options={yearOptions}
              onSelect={(e) => setYear(parseInt(e))}
            />
            <div className="mx-1 px-0">
              <DropdownButtonSelector
                title={dataTypeOptions[dataType]}
                options={dataTypeOptions}
                onSelect={(e) => setDataType(e)}
              />
            </div>
          </ButtonGroup>
        </Row>
        <Row className="py-2 px-3">
          <SunburstPlotly
            data={countriesPlotData}
            year={year}
            dataType={dataType}
          />
        </Row>
      </>
    );
  }
  return <div></div>;
};

export default EmissionSourcesGraph;
