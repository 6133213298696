import React from "react";
import { getUniqueValues } from "../common/lib/utils";
import DropdownSelect from "../common/ui/DropdownSelect";
import { accountsActions } from "./accountsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";

const AccountsSearchBar = () => {
  const dispatch = useDispatch();
  const { accountData: data, accountFilter } = useSelector(
    (state) => state.accounts
  );

  const registrySelector = (
    <DropdownSelect
      data={getUniqueValues(data, "registry", "registry")}
      defaultValue={accountFilter.registry}
      placeholder={"Select registry..."}
      onChange={(selection) => {
        dispatch(
          accountsActions.updateFilter({
            registry: selection.map((obj) => obj.value),
          })
        );
      }}
    />
  );

  const accountTypeSelector = (
    <DropdownSelect
      data={getUniqueValues(data, "accountType", "accountType")}
      defaultValue={accountFilter.accountType}
      placeholder={"Select account type..."}
      onChange={(selection) => {
        dispatch(
          accountsActions.updateFilter({
            accountType: selection.map((obj) => obj.value),
          })
        );
      }}
    />
  );

  const searchAccount = (
    <Form.Control
      type="text"
      placeholder="Search for account or account holder"
      defaultValue={accountFilter.accountText}
      onChange={(e) => {
        e.preventDefault();
        dispatch(
          accountsActions.updateFilter({
            accountText: e.target.value.toLowerCase(),
          })
        );
      }}
    />
  );

  const tradingSystemSelector = (
    <DropdownSelect
      data={getUniqueValues(data, "tradingSystem", "tradingSystem")}
      defaultValue={accountFilter.tradingSystem}
      placeholder={"Select trading system..."}
      onChange={(selection) => {
        dispatch(
          accountsActions.updateFilter({
            tradingSystem: selection.map((obj) => obj.value),
          })
        );
      }}
    />
  );

  return (
    <Container>
      <Row>
        <Col className="px-0 mx-0">{registrySelector}</Col>
        <Col className="px-0 mx-0">{accountTypeSelector}</Col>
      </Row>
      <Row>
        <Col className="px-0">{searchAccount}</Col>
        <Col className="px-0">{tradingSystemSelector}</Col>
      </Row>
    </Container>
  );
};

export default AccountsSearchBar;
