import { useSelector, useDispatch } from "react-redux";
import DropdownSelect from "../common/ui/DropdownSelect";
import { getUniqueValues } from "../common/lib/utils";
import { countriesActions } from "./countriesSlice";

const CountryDataSelector = () => {
  const { countriesData, countriesFilter } = useSelector(
    (state) => state.countries
  );
  const dispatch = useDispatch();

  return (
    <DropdownSelect
      data={getUniqueValues(countriesData, "Registry", "Registry")}
      defaultValue={countriesFilter.countries}
      placeholder={"Select country..."}
      onChange={(selection) =>
        dispatch(
          countriesActions.updateFilter({
            countries: selection.map((obj) => obj.value),
          })
        )
      }
    />
  );
};

export default CountryDataSelector;
