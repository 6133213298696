import { Loader } from "@googlemaps/js-api-loader";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import BoundingSuperClusterAlgorithm from "./superClusterAlgorithm";
import { CLUSTERER_OPTIONS, LOADER_OPTIONS, MAP_OPTIONS } from "./options";
import { Row } from "react-bootstrap";
import Legend from "./Legend";
import { COLOR_CATEGORIES } from "../../common/constants";

/**
 * createMarkers
 * Given a list of installation objects creates an array of google map markers
 * @param {NameSpace} google google names space as created by api
 * @param {Array} installations of installation objects
 * @returns {Array} google maps markers
 */
const createMarkers = (google, installations) =>
  installations
    .filter((inst) => inst.latitudeGoogle && inst.longitudeGoogle)
    .map((inst) => {
      const marker = new google.maps.Marker({
        position: { lat: inst.latitudeGoogle, lng: inst.longitudeGoogle },
        key: inst.id,
        sName: inst.id,
        title: inst.name ? `${inst.id}: ${inst.name}` : `${inst.id}`,
        //icon: `/mapMarkers/${inst.activityCategory}.png`,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 5,
          fillColor: `${COLOR_CATEGORIES[inst.activityCategory]}`,
          fillOpacity: 0.8,
          strokeWeight: 0,
        },
        category: inst.name_category,
      });
      // add event listener
      marker.addListener("click", () =>
        window.open(`/installation/${inst.id}`)
      );
      return marker;
    });

const MapInstallations = () => {
  const mapRef = useRef(null);
  const [gmap, setGmap] = useState({});
  const { filteredInstallationData, installationData } = useSelector(
    (state) => state.installations
  );

  // initialize the basic map when component loads
  // we store the map and the name space into the state to make them accessible
  // later on ( E.g. call a function on the map object: his.state.map.panTo(...)
  // or create a new marker: new this.state.google.maps.Marker(...))
  useEffect(() => {
    new Loader(LOADER_OPTIONS).load().then((google) => {
      const myMap = new google.maps.Map(mapRef.current, MAP_OPTIONS);
      // marker clusterer
      const markerCluster = new MarkerClusterer({
        map: myMap,
        markers: [],
        algorithm: new BoundingSuperClusterAlgorithm(CLUSTERER_OPTIONS),
      });
      setGmap({
        google,
        map: myMap,
        clusterer: markerCluster,
      });
    });
  }, [installationData]);

  // update map for filtering by removing and adding again the markers
  useEffect(() => {
    if (gmap.map) {
      // add the markers
      gmap.clusterer.clearMarkers();
      const markers = createMarkers(gmap.google, filteredInstallationData);
      // note that markers should be added at once. Otherwise performance drops
      // significantly
      gmap.clusterer.addMarkers(markers);
    }
  }, [gmap, filteredInstallationData]);

  return (
    <Fragment>
      <Row className="mx-1">
        <div
          ref={mapRef}
          style={{ height: "400px" }}
          className="box-text"
        ></div>
      </Row>
      <Row className="mx-1">
        <Legend></Legend>
      </Row>
    </Fragment>
  );
};
export default MapInstallations;
