import { Accordion } from "react-bootstrap";

const AccordionItem = (props) => {
  const { header, eventKey, children } = props;
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header className="accordion-title">{header}</Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
};
export default AccordionItem;
