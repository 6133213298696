import { Container, Row, Accordion } from "react-bootstrap";
import MarkdownFile from "../common/ui/MarkdownFile";
import AccordionItem from "../common/ui/AccordionItem";

const Download = () => {
  return (
    <Container>
      <Row>
        <h1 className="headline">Downloads</h1>
      </Row>
      <Row className="mx-1">
        <div className="box-text p-3 mb-2">
          <MarkdownFile filePath="/markdown/downloadPage/downloads.md" />
        </div>
      </Row>
      <Accordion className="mb-2" alwaysOpen>
        <AccordionItem header="Data Sources" eventKey="0">
          <MarkdownFile filePath="/markdown/backgroundPage/dataSources.md" />
        </AccordionItem>
        <AccordionItem header="Related Sites" eventKey="1">
          <MarkdownFile filePath="/markdown/backgroundPage/relatedSites.md" />
        </AccordionItem>
      </Accordion>
    </Container>
  );
};

export default Download;
