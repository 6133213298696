import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./MainNavigation.module.css";

const MainNavigation = () => {
  return (
    <Navbar
      sticky="top"
      //bg="dark"
      expand="sm"
      variant="dark"
      className={`py-0 ${classes.customNavbar}`}
    >
      <Container className="mx-0">
        <Navbar.Brand as={Link} to="/">
          EUETS.INFO
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/installations">
              Installations
            </Nav.Link>
            <Nav.Link as={Link} to="/accounts">
              Accounts
            </Nav.Link>
            <Nav.Link as={Link} to="/countries">
              Countries
            </Nav.Link>
            <Nav.Link as={Link} to="/download">
              Downloads
            </Nav.Link>
            <Nav.Link as={Link} to="/background">
              FAQ
            </Nav.Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">
                                Action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">
                                Something
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown> */}
            <Nav.Link className="ml-auto" as={Link} to="/contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavigation;
