import { Button, Spinner } from "react-bootstrap";
import apiSlice from "../api/apiSlice";
import ErrorPage from "../ErrorPage/ErrorPage";
import TransactionTable from "./TransactionTable";
import { useSelector } from "react-redux";
const Transactions = ({ account = "", holder = "", esd = false }) => {
  const { countriesFilter } = useSelector((state) => state.countries);
  let getTransactions, isUninitialized, isSuccess, isLoading, isError, data;
  if (esd) {
    [
      getTransactions,
      { isUninitialized, isSuccess, isLoading, isError, data },
    ] = apiSlice.endpoints.getEsdTransaction.useLazyQuery();
  } else {
    [
      getTransactions,
      { isUninitialized, isSuccess, isLoading, isError, data },
    ] = apiSlice.endpoints.getTransaction.useLazyQuery();
  }

  if (isUninitialized)
    return (
      <Button
        variant="secondary"
        onClick={(e) => getTransactions({ account, holder })}
      >
        Load transaction data
      </Button>
    );
  if (isLoading)
    return (
      <div className="d-flex justify-content-center m-5">
        <Spinner animation="grow" />
      </div>
    );
  if (isError) return <ErrorPage />;
  if (isSuccess) {
    if (esd)
      return (
        <TransactionTable
          data={data.filter(
            (item) =>
              countriesFilter.countries.includes(
                item.acquiringAccountRegistry
              ) ||
              countriesFilter.countries.includes(
                item.transferringAccountRegistry
              )
          )}
        />
      );
    else return <TransactionTable data={data} />;
  }
};

export default Transactions;
