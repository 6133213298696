import React from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

const columns = [
  {
    id: "id",
    name: "id",
    selector: (row) => row.id,
    omit: true,
  },
  {
    id: "name",
    name: "Name",
    selector: (row) => (
      <Link to={`/account/${row.id}`} target="_blank" rel="noopener noreferrer">
        {row.name}
      </Link>
    ),
    wrap: true,
    sortable: true,
    minWidth: "15%",
    maxWidth: "20%",
  },
  {
    id: "type",
    name: "Account Type",
    selector: (row) => row.accountType,
    wrap: true,
    sortable: true,
    minWidth: "20%",
  },
  {
    id: "registry",
    name: "Registry",
    selector: (row) => row.registry,
    wrap: true,
    sortable: true,
    maxWidth: "10%",
    minWidth: "15%",
  },
  {
    id: "installation",
    name: "Installation",
    selector: (row) =>
      row.installation_id ? (
        <Link
          to={`/installation/${row.installation_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.installation_id}
        </Link>
      ) : (
        ""
      ),
    wrap: true,
    sortable: true,
    maxWidth: "15%",
  },
  {
    id: "isopen",
    name: "Active",
    selector: (row) => row.isOpen,
    format: (row) => (row.isOpen ? <div>&#10003;</div> : <div>&#10005;</div>),
    wrap: true,
    sortable: true,
    center: true,
    maxWidth: "5%",
  },
  {
    id: "holder",
    name: "Account Holder",
    selector: (row) => (
      <Link
        to={`/accountholder/${row.accountHolder_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {row.holderName}
      </Link>
    ),
    wrap: true,
    sortable: true,
    maxWidth: "30%",
    minWidth: "20%",
  },
];

const customStyles = {
  rows: {
    style: {
      fontSize: "1rem",
    },
  },
  head: {
    style: {
      fontSize: "1rem",
      fontWeight: "750",
    },
  },
};

const AccountsTable = ({ data }) => {
  const navigate = useNavigate();
  const onRowClickHandler = (row) => {
    navigate(`/account/${row.id}`);
  };
  return (
    <div className="box-text">
      <DataTable
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        columns={columns}
        data={data}
        fixedHeader
        striped
        responsive
        highlightOnHover
        onRowClicked={onRowClickHandler}
        customStyles={customStyles}
        reorder={true}
      />
    </div>
  );
};

export default AccountsTable;
