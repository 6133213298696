import { useState, useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

/**
 * Component to render markdonw located in public folder
 * @param {String} filePath to markdown in public folder
 * @returns
 */
const MarkdownFile = ({ filePath }) => {
  const [content, setContent] = useState("");
  // get the file content
  useEffect(() => {
    fetch(filePath)
      .then((res) => res.text())
      .then((text) => setContent(text))
      .catch((error) =>
        console.error(`Failed to fetch markdown file: ${error}`)
      );
  }, [filePath]);

  return <ReactMarkdown children={content} />;
};

export default MarkdownFile;
