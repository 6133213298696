//import { useSelector } from "react-redux";
import React from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
const columns = [
  {
    id: "id",
    name: "id",
    selector: (row) => row.id,
    omit: true,
  },
  {
    id: "Installation",
    name: "Installation",
    selector: (row) => {
      if (row.address) {
        return (
          <div style={{ whiteSpace: "pre-wrap" }}>
            <Link
              to={`/installation/${row.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.address}
            </Link>
          </div>
        );
      } else {
        return row.id;
      }
    },
    wrap: true,
    sortable: true,
  },
  {
    id: "Registry",
    name: "Registry",
    selector: (row) => row.registry,
    wrap: true,
    sortable: true,
    maxWidth: "15%",
  },
  {
    id: "Activity",
    name: "Activity",
    selector: (row) => row.activity,
    wrap: true,
    sortable: true,
    maxWidth: "20%",
  },
  {
    id: "Activity Category",
    name: "Activity Category",
    selector: (row) => row.activityCategory,
    wrap: true,
    sortable: true,
    maxWidth: "20%",
    omit: true,
  },
  {
    id: "Sector",
    name: "Sector",
    selector: (row) => {
      return (
        <div style={{ whiteSpace: "break-spaces" }}>
          {row.nace ? `${row.nace_id}: ${row.nace}` : ""}
        </div>
      );
    },
    warp: true,
    sortable: false,
  },
];

const customStyles = {
  rows: {
    style: {
      fontSize: "1rem",
    },
  },
  head: {
    style: {
      fontSize: "1rem",
      fontWeight: "750",
    },
  },
};

const InstallationsTable = () => {
  const { filteredInstallationData } = useSelector(
    (state) => state.installations
  );
  const navigate = useNavigate();

  const onRowClickHandler = (row) => {
    navigate(`/installation/${row.id}`);
  };

  return (
    <div className="box-text">
      <DataTable
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        columns={columns}
        data={filteredInstallationData}
        fixedHeader
        striped
        responsive
        highlightOnHover
        onRowClicked={onRowClickHandler}
        customStyles={customStyles}
        reorder={true}
      />
    </div>
  );
};

export default InstallationsTable;
