import { useParams } from "react-router-dom";
import {
  useGetAccountQuery,
  useGetInstallationQuery,
  useGetComplianceQuery,
} from "../api/apiSlice";
import { Col, Container, Row } from "react-bootstrap";
import InstallationInformation from "./InstallationInformation";
import AccountInformation from "./AccountInformation";
import ComplianceInformation from "./ComplianceInformation";
import LoadingOverlay from "../common/ui/LoadingOverlay";

const Installation = () => {
  const { id } = useParams();

  // fetch data
  const { data: installations } = useGetInstallationQuery({
    id,
  });
  const { data: accounts } = useGetAccountQuery({
    installation: id,
  });
  const { data: compliance } = useGetComplianceQuery({ id });
  if (installations && accounts && compliance) {
    // determine the valid account id for link to eutl page
    let installation;
    if (accounts.length === 1) {
      // if we have only one account choose that as the main account
      installation = { ...installations[0], validAccountId: accounts[0].id };
    } else {
      const openAccounts = accounts.filter((item) => item.isOpen);
      // if we have open accounts, simply choose the first one in the list
      if (openAccounts.length > 0) {
        installation = {
          ...installations[0],
          validAccountId: openAccounts[0].id,
        };
      } else {
        // if there is no open account take the one with the latest closing date
        const acc = accounts.filter((item) => item.accountType_id === "100-7");
        if (acc.length > 0) {
          installation = {
            ...installations[0],
            validAccountId: acc[0].id,
          };
        } else {
          installation = {
            ...installations[0],
            validAccountId: accounts[0].id,
          };
        }
      }
    }
    return (
      <Container>
        <Row>
          <h1 className="headline">
            Installation
            <br />
            {`${installation.id}`}
            {installation.name ? `: ${installation.name}` : ""}
          </h1>
        </Row>
        <Row>
          <Col lg={6} sd={12}>
            <InstallationInformation installation={installation} />
          </Col>
          <Col lg={6} sd={12}>
            <AccountInformation accounts={accounts} />
          </Col>
        </Row>
        <ComplianceInformation
          data={compliance}
          account_id={installation.validAccountId}
          registry={installation.registry_id}
        />
      </Container>
    );
  }
  return <LoadingOverlay headline="Installation" />;
};

export default Installation;
