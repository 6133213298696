import { Container } from "react-bootstrap";
import { useGetAccountsQuery } from "../api/apiSlice";
import AccountsTable from "./AccountsTable";
import AccountsSearchBar from "./AccountsSearchBar";
import { useSelector } from "react-redux";
import LoadingOverlay from "../common/ui/LoadingOverlay";

const Accounts = () => {
  const { isSuccess } = useGetAccountsQuery();
  const { filteredAccountData } = useSelector((state) => state.accounts);

  if (isSuccess) {
    return (
      <Container>
        <h1 className="headline">Accounts</h1>
        <div className="my-1">
          <AccountsSearchBar />
        </div>
        <AccountsTable data={filteredAccountData} />
      </Container>
    );
  }
  return <LoadingOverlay headline="Accounts" />;
};
export default Accounts;
