import Chart from "react-apexcharts";
import { formatNumber } from "../common/lib/utils";
import { Link } from "react-router-dom";

const AllowanceStockGraph = ({ data }) => {
  // extract data
  const unitConversion = (x) => (x / 1000000).toFixed(1);
  const dates = data.map((item) => Date.parse(item.date));
  const series = data.reduce(
    (agg, item) => {
      agg
        .filter((i) => i.name === "EU Allowances")[0]
        .data.push(unitConversion(item.General_allowances));
      agg
        .filter((i) => i.name === "Emission Reduction Units")[0]
        .data.push(unitConversion(item.ERU));
      agg
        .filter((i) => i.name === "Certified Emission Units")[0]
        .data.push(unitConversion(item.CER));
      agg
        .filter((i) => i.name === "Swiss Allowances")[0]
        .data.push(unitConversion(item.Swiss_General_Allowances));
      return agg;
    },
    [
      { name: "EU Allowances", data: [], color: "#444242" },
      { name: "Emission Reduction Units", data: [], color: "#1f78b4" },
      { name: "Certified Emission Units", data: [], color: "#b2df8a" },
      { name: "Swiss Allowances", data: [], color: "#33a02c" },
    ]
  );

  // formatting of graph
  const options = {
    chart: {
      id: "basic-bar",
      type: "area",
      stacked: true,
    },
    xaxis: {
      categories: dates,
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: (x) => formatNumber(x, " "),
      },
      title: {
        text: "Allowances [Million]",
        style: {
          cssClass: "graphs__yaxis",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      offsetY: 10,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "solid",
    },
  };
  return (
    <div className="allowance-stock-chart">
      <Chart
        options={options}
        series={series}
        type="area"
        width="100%"
        height="400px"
      />
      <p className="footnote">
        <em>Source</em>: Registry holdings as provided by the{" "}
        <Link
          to="https://ec.europa.eu/clima/ets/registryHoldings.do?search=Search"
          target="_blank"
        >
          EUTL
        </Link>
        . The graph is updated daily. Aviation allowances are not shown
      </p>
    </div>
  );
};

export default AllowanceStockGraph;
