import { Container, Row, Accordion, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetAccountHolderQuery } from "../api/apiSlice";
import AccountsTable from "../Accounts/AccountsTable";
import LoadingOverlay from "../common/ui/LoadingOverlay";
import AccordionItem from "../common/ui/AccordionItem";
import Transactions from "../Transactions/Transactions";

const AccountHolder = () => {
  const { id } = useParams();
  const { data: accounts } = useGetAccountHolderQuery({
    id,
  });

  if (accounts) {
    const accountHolder = accounts[0];
    return (
      <Container>
        <h1 className="headline">
          Account Holder
          <br />
          {accountHolder.holderName}
        </h1>
        <Row>
          <Col className="my-1">
            <div className="box-text p-3" style={{ whiteSpace: "pre-line" }}>
              {accountHolder.holderAddress}
            </div>
          </Col>
        </Row>
        <Accordion defaultActiveKey="0" alwaysOpen>
          <AccordionItem eventKey="0" header="Accounts">
            <AccountsTable data={accounts} />
          </AccordionItem>
          <AccordionItem eventKey="1" header="Transactions">
            <Transactions holder={id} />
          </AccordionItem>
        </Accordion>
      </Container>
    );
  }
  return <LoadingOverlay headline="Account Holder" />;
};

export default AccountHolder;
