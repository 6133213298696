//import { useSelector } from "react-redux";
import React from "react";
import { getUniqueValues } from "../common/lib/utils";
import DropdownSelect from "../common/ui/DropdownSelect";
import { Row, Col, Form, Container } from "react-bootstrap";
import { installationsActions } from "./installationsSlice";
import { useDispatch, useSelector } from "react-redux";

const InstallationsSearchBar = () => {
  const dispatch = useDispatch();
  const {
    installationData: data,
    installationFilter,
    filteredInstallationData: filteredData,
  } = useSelector((state) => state.installations);

  const registrySelector = (
    <DropdownSelect
      data={getUniqueValues(data, "registry", "registry")}
      defaultValue={installationFilter.registry}
      placeholder={"Select registry..."}
      onChange={(selection) => {
        dispatch(
          installationsActions.updateFilter({
            registry: selection.map((obj) => obj.value),
          })
        );
      }}
    />
  );

  const activitySelector = (
    <DropdownSelect
      data={getUniqueValues(
        filteredData,
        "activityCategory",
        "activityCategory"
      )}
      defaultValue={installationFilter.activityCategory}
      placeholder={"Select activity..."}
      onChange={(selection) => {
        dispatch(
          installationsActions.updateFilter({
            activityCategory: selection.map((obj) => obj.value),
          })
        );
      }}
    />
  );

  const naceSelector = (
    <DropdownSelect
      data={getUniqueValues(filteredData, "naceCategory", "naceCategory")}
      defaultValue={installationFilter.naceCategory}
      placeholder={"Select sector..."}
      onChange={(selection) => {
        dispatch(
          installationsActions.updateFilter({
            naceCategory: selection.map((obj) => obj.value),
          })
        );
      }}
    />
  );

  // const tradingSystemSelector = (
  //   <DropdownSelect
  //     data={getUniqueValues(data, "tradingSystem", "tradingSystem")}
  //     defaultValue={installationFilter.tradingSystem}
  //     placeholder={"Select trading system..."}
  //     onChange={(selection) => {
  //       dispatch(
  //         installationsActions.updateFilter({
  //           tradingSystem: selection.map((obj) => obj.value),
  //         })
  //       );
  //     }}
  //   />
  // );

  const searchInstallation = (
    <Form.Control
      type="text"
      defaultValue={installationFilter.installationText}
      placeholder="Search installation"
      onChange={(e) => {
        e.preventDefault();
        dispatch(
          installationsActions.updateFilter({
            installationText: e.target.value.toLowerCase(),
          })
        );
      }}
    />
  );

  return (
    <Container>
      <Row>
        <Col className="px-0 mx-0">{registrySelector}</Col>
        <Col className="px-0 mx-0">{activitySelector}</Col>
        <Col className="px-0 mx-0">{naceSelector}</Col>
        {/* <Col className="px-0 mx-0">{tradingSystemSelector}</Col> */}
      </Row>
      <Row>
        <Col className="px-0">{searchInstallation}</Col>
      </Row>
    </Container>
  );
};

export default InstallationsSearchBar;
