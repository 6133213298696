import { Col, Container, Row } from "react-bootstrap";
import InstallationsTable from "./InstallationsTable";
import { useGetInstallationsQuery } from "../api/apiSlice";
import InstallationsSearchBar from "./InstallationsSearchBar";
import MapInstallations from "./MapInstallations/MapInstallations";
import LoadingOverlay from "../common/ui/LoadingOverlay";

const Installations = () => {
  const { isSuccess } = useGetInstallationsQuery({});
  if (isSuccess) {
    return (
      <Container>
        <h1 className="headline">Installations</h1>
        <div className="my-1">
          <InstallationsSearchBar />
        </div>
        <div className="my-1">
          <MapInstallations />
        </div>
        <Row>
          <Col>
            <InstallationsTable />
          </Col>
        </Row>
      </Container>
    );
  }

  return <LoadingOverlay headline="Installations" />;
};

export default Installations;
