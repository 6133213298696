import { useGetCountriesQuery } from "../api/apiSlice";
import LoadingOverlay from "../common/ui/LoadingOverlay";
import { Container, Row, Col } from "react-bootstrap";
import CountryDataSelector from "./CountryDataSelector";
import Compliance from "./Compliance/Compliance";
import EmissionSourcesGraph from "./Compliance/EmissionSourcesGraph";
import MarkdownFile from "../common/ui/MarkdownFile";
import { useSelector } from "react-redux";

const Countries = () => {
  useGetCountriesQuery();
  const countryData = useSelector((state) => state.countries.countriesData);
  if (countryData && countryData.length > 0) {
    return (
      <Container>
        <Row>
          <h1 className="headline">Country Overview</h1>
        </Row>
        <Row className="mb-1">
          <Col sm="12" lg="5">
            <div className="box-text px-3 py-4">
              <MarkdownFile filePath="/markdown/countryPage/descriptionPage.md" />
            </div>
            <CountryDataSelector />
          </Col>
          <Col sm="12" lg="7">
            <div className="box-text">
              <EmissionSourcesGraph />
            </div>
          </Col>
        </Row>
        <Compliance />
      </Container>
    );
  }
  return <LoadingOverlay headline="Country Overview" />;
};

export default Countries;
