import DataTable from "react-data-table-component";
import { formatDate, formatNumber } from "../common/lib/utils";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
const TransactionTable = ({ data }) => {
  // states and buttons to manage visible columns
  const [hiddenColumns, setHiddenColumns] = useState({
    accountType: true,
    accountHolder: true,
    account: false,
    transactionType: false,
  });
  const MyButton = ({ onClick, label }) => (
    <Button
      variant="secondary"
      onClick={onClick}
      className="mx-1 rounded flex-fill"
    >
      {label}
    </Button>
  );

  if (data.length === 0)
    return <p>Account is not involved in any transactions</p>;

  const columns = [
    {
      id: "date",
      name: "Date",
      selector: (row) => formatDate(row.date),
      maxWidth: "5%",
      sortable: true,
    },
    {
      id: "transactionType",
      name: "Transaction Type",
      selector: (row) => row.supplementaryTypeMain,
      wrap: true,
      maxWidth: "20%",
      sortable: true,
      omit: hiddenColumns.transactionType,
    },
    {
      id: "linkEutl",
      name: "EUTL Page",
      selector: (row) => {
        if (row.tradingSystem_id === "esd")
          return (
            <Link
              to={`https://ec.europa.eu/clima/ets/esdSingleTransaction.do?action=detail&transactionID=${row.transactionID}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.transactionID}
            </Link>
          );
        else
          return (
            <Link
              to={`https://ec.europa.eu/clima/ets/singleTransaction.do?action=detail&languageCode=en&transactionID=${row.transactionID}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.transactionID}
            </Link>
          );
      },
      maxWidth: "5%",
    },
    {
      id: "transferringAccount",
      name: "Transferring Account",
      selector: (row) => (
        <Link
          to={`/account/${row.transferringAccount_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.transferringAccountName}
        </Link>
      ),
      wrap: true,
      maxWidth: "25%",
      sortable: true,
      omit: hiddenColumns.account,
    },
    {
      id: "transferringAccountType",
      name: "Transf. Acc. Type",
      selector: (row) => row.transferringAccountAccountType,
      wrap: true,
      maxWidth: "20%",
      sortable: true,
      omit: hiddenColumns.accountType,
    },
    {
      id: "transferringAccountHolder",
      name: "Transf. Holder",
      selector: (row) => (
        <Link
          to={`/account/${row.transferringAccountAccountHolder_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.transferringAccountHolderName}
        </Link>
      ),
      wrap: true,
      maxWidth: "20%",
      sortable: true,
      omit: hiddenColumns.accountHolder,
    },
    {
      id: "acquiringAccount",
      name: "Acquiring Account",
      selector: (row) => (
        <Link
          to={`/account/${row.acquiringAccount_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.acquiringAccountName}
        </Link>
      ),
      wrap: true,
      maxWidth: "25%",
      sortable: true,
      omit: hiddenColumns.account,
    },
    {
      id: "acquiringAccountType",
      name: "Acqu. Acc. Type",
      selector: (row) => row.acquiringAccountAccountType,
      wrap: true,
      maxWidth: "20%",
      sortable: true,
      omit: hiddenColumns.accountType,
    },
    {
      id: "acquiringAccountHolder",
      name: "Acqu. Holder",
      selector: (row) => (
        <Link
          to={`/account/${row.acquiringAccountAccountHolder_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.acquiringAccountHolderName}
        </Link>
      ),
      wrap: true,
      maxWidth: "20%",
      sortable: true,
      omit: hiddenColumns.accountHolder,
    },
    {
      id: "amount",
      name: "Amount",
      selector: (row) => formatNumber(row.amount),
      sortable: true,
      maxWidth: "5%",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        fontSize: "0.7rem",
      },
    },
    head: {
      style: {
        fontSize: "0.7rem",
        fontWeight: "750",
      },
    },
  };
  return (
    <div className="box-text">
      <ButtonGroup className="w-100">
        <MyButton
          onClick={() => {
            setHiddenColumns({
              ...hiddenColumns,
              transactionType: !hiddenColumns.transactionType,
            });
          }}
          label={`${
            hiddenColumns.transactionType ? "Show" : "Hide"
          } Transaction Type`}
        />

        <MyButton
          onClick={() => {
            setHiddenColumns({
              ...hiddenColumns,
              account: !hiddenColumns.account,
            });
          }}
          label={`${hiddenColumns.account ? "Show" : "Hide"} Accounts`}
        />

        <MyButton
          onClick={() => {
            setHiddenColumns({
              ...hiddenColumns,
              accountType: !hiddenColumns.accountType,
            });
          }}
          label={`${hiddenColumns.accountType ? "Show" : "Hide"} Account Type`}
        />

        <MyButton
          onClick={() => {
            setHiddenColumns({
              ...hiddenColumns,
              accountHolder: !hiddenColumns.accountHolder,
            });
          }}
          label={`${
            hiddenColumns.accountHolder ? "Show" : "Hide"
          } Account Holder`}
        />
      </ButtonGroup>
      <DataTable
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        columns={columns}
        data={data}
        fixedHeader
        striped
        responsive
        highlightOnHover
        //onRowClicked={onRowClickHandler}
        customStyles={customStyles}
        reorder={true}
      />
    </div>
  );
};

export default TransactionTable;
