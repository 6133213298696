import { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import AccordionItem from "../common/ui/AccordionItem";
import MarkdownFile from "../common/ui/MarkdownFile";

const Background = () => {
  return (
    <Fragment>
      <Accordion defaultActiveKey="0" className="mb-2" alwaysOpen>
        <AccordionItem header="Where do the data come from?" eventKey="0">
          <MarkdownFile filePath="/markdown/backgroundPage/dataSources.md" />
        </AccordionItem>
        {/* <AccordionItem header="What is the Effort Sharing System?" eventKey="1">
          <MarkdownFile filePath="/markdown/backgroundPage/dataSources.md" />
        </AccordionItem> */}
      </Accordion>
    </Fragment>
  );
};

export default Background;
