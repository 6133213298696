import { createSlice } from "@reduxjs/toolkit";
import apiSlice from "../api/apiSlice";
import { groupData } from "../common/lib/utils";

const initialState = {
  countriesFilter: {
    dataType: "Activity",
    countries: [],
  },
  countriesData: [],
  countriesPlotData: [],
};

const createPlotData = (countriesData, countriesFilter) => {
  if (countriesFilter.countries.length > 0) {
    const filteredData = countriesData.filter((item) =>
      countriesFilter.countries.includes(item.Registry)
    );
    const aggregatedData = groupData(
      filteredData,
      [countriesFilter.dataType, "year"],
      ["allocated", "surrendered", "verified"]
    );

    return aggregatedData;
  }
  return [];
};

const countriesSlice = createSlice({
  name: "countries",
  initialState: initialState,
  reducers: {
    updateFilter: (state, action) => {
      // assume the action to provide the field of the filter to be updated
      // together with the new values for that field, i.e.
      // payload = {field: FilterValue}
      state.countriesFilter = {
        ...state.countriesFilter,
        ...action.payload,
      };
      state.countriesPlotData = createPlotData(
        state.countriesData,
        state.countriesFilter
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getCountries.matchFulfilled,
      (state, action) => {
        state.countriesData = action.payload;
      }
    );
  },
});

export const countriesActions = countriesSlice.actions;
export default countriesSlice.reducer;
