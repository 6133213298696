import { Accordion, Container, Row, Spinner } from "react-bootstrap";
import { useGetHoldingQuery } from "../api/apiSlice";
import AllowanceStockGraph from "./AllowanceStockGraph";
import AccordionItem from "../common/ui/AccordionItem";
import { Link } from "react-router-dom";

const LandingPage = () => {
  // Get data for allowance holding
  const { data: holdingsData } = useGetHoldingQuery();

  return (
    <Container>
      <Row>
        <h1 className="headline">Welcome to EUETS.INFO</h1>
      </Row>
      <Row className="mx-1">
        <div className="box-text p-4 px-5 mb-5">
          <p>
            The European Union Emissions Trading System (EUETS) regulates
            greenhouse gas emissions of energy and energy-intensive industries
            as well as inner-European aviation. With more than 13'000 regulated
            entities and an emissions cap of about 1.8 billion tCO<sub>2</sub>{" "}
            (2019), the EUETS is the world's largest carbon market and a
            centerpiece of EU climate policy.
          </p>
          <p>
            EUETS.INFO provides information about emissions and compliance
            behavior under the EUETS. We provide this information on the level
            of <Link to="/installations">emitting installations</Link> as well
            as on the <Link to="/registries">country level</Link>. We enable you
            to search <Link to="/accounts">accounts</Link>, the administrative
            backbones of the EUETS, and analyze their transactions. In doing so,
            we increase the transparency of the EUETS and facilitate further
            empirical research on trading and compliance behavior in the EUETS.
            The European Union Transaction Log (EUTL) is our main data source.
            Whereas the EUTL provides all data, they are not easily accessible.
          </p>
          <p>
            Following an open data approach, EUETS.INFO provides all{" "}
            <Link to="/download">processed data</Link>. For transparency, we
            also provide the{" "}
            <Link to="https://github.com/jabrell/eutl_scraper" target="_blank">
              routines to download and process the original EUTL data
            </Link>
            . To lower the entry barrier to dig into the analysis of EUTL data,
            we also provide the Python package{" "}
            <Link to="https://github.com/jabrell/pyeutl" target="_blank">
              pyeutl
            </Link>{" "}
            implementing the data model and facilitating accessing the dataset.
          </p>
        </div>
      </Row>
      <Accordion alwaysOpen>
        <AccordionItem header="Stock of Emission Allowances in the EUETS">
          {holdingsData ? (
            <AllowanceStockGraph data={holdingsData} />
          ) : (
            <div className="d-flex justify-content-center m-5">
              <Spinner animation="grow" />
              <p>
                <br />
                Load data
              </p>
            </div>
          )}
        </AccordionItem>
      </Accordion>
    </Container>
  );
};

export default LandingPage;
