import { createSlice } from "@reduxjs/toolkit";
import apiSlice from "../api/apiSlice";

const filterInstallations = (data, installationFilter) =>
  data.filter((item) => {
    let isRegistry = true;
    let isActivity = true;
    let isNace = true;
    let isInstallation = true;
    let isTradingSystem = true;
    if (installationFilter.registry.length !== 0) {
      isRegistry = installationFilter.registry.includes(item.registry);
    }
    if (installationFilter.activityCategory.length !== 0) {
      isActivity = installationFilter.activityCategory.includes(
        item.activityCategory
      );
    }
    if (installationFilter.naceCategory.length !== 0) {
      isNace = installationFilter.naceCategory.includes(item.naceCategory);
    }
    if (installationFilter.tradingSystem.length !== 0) {
      isTradingSystem = installationFilter.tradingSystem.includes(
        item.tradingSystem
      );
    }
    if (installationFilter.installationText.length !== 0) {
      isInstallation =
        item.address &&
        item.address
          .toLowerCase()
          .includes(installationFilter.installationText);
    }
    return (
      isRegistry && isActivity && isNace && isInstallation && isTradingSystem
    );
  });

const initialState = {
  installationFilter: {
    registry: [],
    activityCategory: [],
    naceCategory: [],
    installationText: "",
    tradingSystem: [],
  },
  installationData: [],
  filteredInstallationData: [],
};

const installationsSlice = createSlice({
  name: "installations",
  initialState: initialState,
  reducers: {
    updateFilter: (state, action) => {
      // assume the action to provide the field of the filter to be updated
      // together with the new values for that field, i.e.
      // payload = {field: FilterValue}
      state.installationFilter = {
        ...state.installationFilter,
        ...action.payload,
      };
      state.filteredInstallationData = filterInstallations(
        state.installationData,
        state.installationFilter
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getInstallations.matchFulfilled,
      (state, action) => {
        state.installationData = action.payload.filter(
          (item) => item.tradingSystem !== "EU Effort Sharing Decision"
        );
        state.filteredInstallationData = filterInstallations(
          state.installationData,
          state.installationFilter
        );
      }
    );
  },
});

export const installationsActions = installationsSlice.actions;
export default installationsSlice.reducer;
